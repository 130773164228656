<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="日期：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <el-form-item label="会议/期刊：">
        <dic-radio-button
          :code.sync="queryInfo.journalMeeting"
          query-name="journalMeeting"
          name="会议/期刊"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-tag-button
          :code.sync="queryInfo.technologyName"
          query-name="technologyName"
          name="技术类别"
          tag="技术"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >
          <el-button @click="dialogVisibleTalent = true"
            >批量上传<i class="el-icon-upload2"></i
          ></el-button>
          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color:#606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor'
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor'
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="请输入论文中文标题"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="infoTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column label="序号" align="center" width="100">
          <template v-slot="scope">
            <span>{{
              (queryInfo.pageNum - 1) * queryInfo.pageSize + (scope.$index + 1)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
          prop="dataIntegrity"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor:pointer;">
              <el-progress
                type="circle"
                :percentage="row.dataIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="paperTitleCha"
          label="论文标题(中文)"
          align="center"
        />
        <el-table-column
          prop="paperTitleEng"
          label="论文标题(英文)"
          align="center"
          width="250"
        />
        <el-table-column
          prop="journalMeeting"
          label="会议/刊期"
          align="center"
        />
        <el-table-column
          prop="technologyCategory"
          label="技术类别"
          align="center"
        />
        <el-table-column prop="paperYear" label="论文年份" align="center" />
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="100"
        />
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        />
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{ row }">
            <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              content="查看"
              icon="jr-iconjr-icon-eye"
              @buttonClick="seeClick(row)"
            ></button-table>
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              content="删除"
              @buttonClick="deleteItem(row)"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 批量上传 -->
    <el-dialog
      title="批量上传"
      :visible.sync="dialogVisibleTalent"
      width="70%"
      @close="handleCloseTalent"
      v-loading="loading"
      center
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="22">
          <upload-report-pdf
            @filesDo="filesDo"
            ref="uploadTalent"
          ></upload-report-pdf>
        </el-col>
      </el-row>
      <el-table
        :data="dataData17"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center'
        }"
        @selection-change="handleSelectionChange"
        style="overflow:auto"
        height="300"
      >
        <el-table-column
          type="index"
          width="200"
          :label="'序号' + '(总数为' + dataData17.length + ')'"
        >
        </el-table-column>
        <el-table-column prop="name" label="文件名"> </el-table-column>
        <el-table-column prop="size" label="大小">
          <template slot-scope="scope">
            <span>{{ (scope.row.size / 1024 / 1024).toFixed(2) + "MB" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="size" label="大小"></el-table-column> -->
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="padding: 0; color: red"
              @click="deleteTalentsData(scope.row, scope.$index)"
            >
              <i class="jr-iconjr-icon-delect" />
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="uploadTalents"
          style="margin-right:10px"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleTalent = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CascaderArea from "@/components/CascaderArea.vue";
import ImportData from "@/components/ImportData.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DataTime from "@/views/CaseData/dataTime";
import Popover from "@/components/Popover.vue";
import { pagination } from "@/mixins/pagination";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getInfo,
  getInfoById,
  addInfo,
  deleteInfo,
  uploadInfo
} from "@/api/paper";
import { exportData } from "@/utils/tool";
import { downFile } from "@/utils/tool";
import UploadReportPdf from "@/views/ResearchReport/com/uploadReportPdf.vue";
import { uploadAdminAndPage } from "@/api/common.js";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    UploadReportPdf,
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    CascaderArea,
    Popover,
    DicCheckboxGroup
  },

  data() {
    return {
      // 查询
      errorMes: [],
      loading: false,
      dataData17: [],
      dialogVisibleTalent: false,
      queryInfo: {
        columnName: "update_time",
        pageSize: 10,
        pageNum: 1,
        queryConditions: "", // 查询条件
        years: null, // 年
        month: null, // 月
        day: null, // 日
        journalMeeting: "", // 会议/期刊
        technologyCategory: [], // 技术类别
        technologyName: "", // 技术辅助标签
        order: 1, // 0正序 1倒叙
        id: [], // 筛选编号
        size: "" // 所选条数
      },
      infoTable: [], // 表格数据
      tags: [
        {
          name: "会议/期刊",
          tagName: "journalMeeting"
        },
        {
          name: "技术类别",
          tagName: "technologyName",
          valLength: 0
        }
      ],
      editTableObj: {
        technologyCategory: [], // 技术类别
        technologyChildrenId: [] // 技术类别子标签
      },
      dialogVisible: false,
      delete: {
        id: [],
        multiId: []
      },
      valueI: 0
    };
  },
  created() {
    this.search();
  },

  methods: {
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },
    downloadClick(row) {
      downFile(
        { urlStr: row.paperFile },
        "/paperBasic/urlStr",
        "application/pdf",
        row.paperFile.substring(row.paperFile.lastIndexOf("/") + 1) //指定下载文件名
      );
    },
    seeClick(row) {
      if (row.url) {
        this.fileUrl = row.url;
        window.open(this.fileUrl);
      } else {
        this.$alert("该论文数据暂无附件!");
      }
    },
    fileClick() {
      this.search();
    },
    async search() {
      const res = await getInfo(this.queryInfo);

      if (res.code === 200) {
        this.infoTable = res.data.list;

        this.total = res.data.total;

        this.infoTable.forEach(el => {
          if (!el.projectDataIntegrity) {
            el.projectDataIntegrity = 0;
          }
        });
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.projectFieldType = this.editTableObj.projectFieldType.concat(
          this.editTableObj.fieldChildrenId
        );
      }

      if (this.editTableObj.industryChildrenId) {
        this.editTableObj.projectIndustryType = this.editTableObj.projectIndustryType.concat(
          this.editTableObj.industryChildrenId
        );
      }

      this.editTableObj.projectIndustryType = [
        ...new Set(this.editTableObj.projectIndustryType)
      ];
      this.editTableObj.projectFieldType = [
        ...new Set(this.editTableObj.projectFieldType)
      ];

      const res = await updateProjectData(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible = false;

      this.search();
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;

      this.$refs.checkboxProject.clear();
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateChange(year, month, day) {
      this.queryInfo.years = year;

      this.queryInfo.month = month;

      this.queryInfo.day = day;

      this.search();
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];

      if (!this.tags.some(item => item.tagName === queryName)) {
        if (name === "技术类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex(el => {
        return el.name === name;
      });

      switch (name) {
        case "技术类别":
          this.queryInfo.technologyCategory = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.technologyCategory = this.queryInfo.technologyCategory.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }

      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "technologyName":
          this.queryInfo.technologyName = "";
          this.queryInfo.technologyCategory = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    editItem(row) {
      // this.$router.push({
      //   name: 'Add',
      //   query: {
      //     infoType: 1,
      //     id: row.id,
      //   },
      // })
      let text = this.$router.resolve({
        path: "/paper/add",
        query: {
          infoType: 1,
          id: row.id
        }
      });
      window.open(text.href, "_blank");
    },

    addItem() {
      this.$router.push({
        name: "Add",
        query: {
          infoType: 0
        }
      });
    },

    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order;

      if (this.queryInfo.order) {
        this.queryInfo.order = 1;
      } else {
        this.queryInfo.order = 0;
      }

      this.search();
    },
    // 正序
    caretTop() {
      this.queryInfo.order = 0;

      this.search();
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 1;

      this.search();
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map(item => {
        return item.id;
      });

      this.queryInfo.id = val.map(item => {
        return item.id;
      });
    },
    _deleteProject(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteInfo(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    },
    deleteItem(row) {
      this.delete.id.push(row.id);

      this._deleteProject(this.delete.id);
    },
    multiDelete() {
      if (this.delete.multiId.length > 0) {
        this._deleteProject(this.delete.multiId);
      } else {
        this.$message.warning("请选择至少一条数据");
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "领域类别" || column.label === "行业类别") {
        return "cursor:pointer";
      }
    },
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { id: [], size: "" });
      } else if (val == 0 && this.queryInfo.id.length) {
        Object.assign(this.queryInfo, {
          id: this.queryInfo.id
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/paperBasic/zipLoad", // 路径
        "application/zip", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "论文数据"
      );
    },
    deleteProuct() {},
    // 批量上传
    filesDo(data) {
      let FileExt = data.map(item => {
        return item.name.replace(/.+\./, "");
      });
      let flag = FileExt.map(item => {
        return ["pdf"].includes(item);
      });
      let errorMe = [];
      flag.forEach((item, index) => {
        if (!item) {
          errorMe.push(index);
        }
      });
      this.dataData17 = data;
      this.errorMes = errorMe.map(item => {
        return (item = item + 1);
      });
    },
    // 批量上传
    deleteTalentsData(data, index) {
      this.dataData17.splice(index, 1);
      for (let i = 0; i < this.errorMes.length; i++) {
        if (this.errorMes[i] == index + 1) {
          for (let j = 0; j < this.errorMes.length; j++) {
            if (this.errorMes[j] > this.errorMes[i]) {
              this.errorMes[j] = this.errorMes[j] - 1;
            }
          }
          this.errorMes.splice(i, 1);
        }
      }
    },
    async uploadTalents() {
      if (this.errorMes.length > 0) {
        const errorMes1 = this.errorMes.join(",");
        this.$confirm(
          "第" + errorMes1.substring(0, 300) + "条数据格式错误",
          "提示"
        );
      } else {
        if (this.dataData17.length <= 10000) {
          const form = new FormData();
          this.dataData17.forEach((item, index) => {
            form.append("files", this.dataData17[index]);
          });
          this.loading = true;
          // const res = await uploadAdminAndPage(form)
          //   if(res.code == 200){
          // const message1 = res.data
          // const value1 = {
          //   reportKey:message1.fileAddr,
          //   reportUrl:message1.fileLook,
          //   reportPage:message1.pageList,
          //   md5List:message1.md5List
          //   }
          const res1 = await uploadInfo(form);
          if (res1.code !== 500) {
            this.search();
            this.dialogVisibleTalent = false;
            // this.$message.success(res1.msg)
            this.$confirm(res1.msg, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout5"
            }).then(() => {});
          } else if (res1.code == 500) {
            this.$confirm(res1.msg, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout5"
            }).then(() => {});
            this.dialogVisibleTalent = false;
          }
          this.loading = false;
          // }else{
          //   this.$confirm(res.msg, "提示",{
          //        dangerouslyUseHTMLString: true,
          //        customClass: 'message-logout5'
          //     })
          //   this.loading = false
          //   this.dialogVisibleTalent = false
          // }
        } else {
          this.$message.error("上传失败");
        }
      }
    },
    handleCloseTalent() {
      this.$refs.uploadTalent.fileArray = [];
      this.dataData17 = [];
    }
  }
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
/deep/ .el-button + .el-button {
  margin-left: 0px;
}
</style>

<style lang="less">
.message-logout5 {
  .el-message-box__message p {
    margin: 0;
    line-height: 24px;
    max-height: 300px !important;
    overflow: auto;
  }
  .el-message-box__container {
    max-height: 300px !important;
    overflow: auto;
  }
}
</style>
